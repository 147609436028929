import React from "react";
import { createPortal } from "react-dom";
import styled from 'styled-components';
import { Button } from '.';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,.2);
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const Box = styled.div`
  max-width: 400px;
  background: white; 
  border-radius: 20px;
  padding: 24px;
  color: ${props => props.theme.colours.dark};
  display:flex;
  flex-direction: column;
  align-items: center;
  & > p {
    font-size: 18px;
    color: ${props => props.theme.colours.dark};
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  width:100%;
  Button {
    font-size: 18px;
  }
`;

export default function Modal({ children, onClick }) {
  const doNothing = (event) => {
    event.preventDefault();
    return false;
  }
  return (
    createPortal(
      <Wrapper onClick={onClick}>
        <Box onClick={doNothing}>
          {children}
          <ButtonContainer><Button onClick={onClick}>Close</Button></ButtonContainer>
        </Box>
      </Wrapper>,
      document.getElementById("modal_root"),
    )
  )
};
