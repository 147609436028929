import React from 'react';
import styled from 'styled-components';
import axios from "axios";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PageSection, PageDetails, PageImg } from '../../components';
import SocialButtons from '../../components/SocialButtons';
import whisperJpg from '../../img/whisper.jpg'
import whisperWebp from '../../img/whisper.webp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactImg = styled(PageImg)`
  background-image: url(${() => isWebpSupported() ? whisperWebp : whisperJpg});
  background-size: cover;
  background-position: 50% 20%;
`;

const StyledForm = styled(Form)`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 16px;
width: 100%;
max-width: 600px;
& > button {
  max-width: 150px;
  width: 100%;
  height: 50px;
  border-radius: 0;
  background-color: ${props => props.theme.colours.secondary};
  border: 0;
  margin-top: 10px;
  z-index:1;
  font-size: 16px;
}
& > button[disabled] {
  background-color: gray;
}
& > label {
  display: none;
}
`;
const StyledField = styled(Field)`
  margin-top: 10px;
  padding: 4px 12px;
  width: 100%;
  border: 0;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 0;
  line-height: 1.5;
  color: ${props => props.theme.colours.dark};
  & + ErrorMessage {
    color: red;
  }
  &::placeholder {
    color: ${props => props.theme.colours.dark};
  }
  &[as="textarea"] {
    min-height: 200px;
    padding-bottom: 20px;
  }
`;
const SocialButtonsContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 8px;
`;

export default function ContactSection() {
    const emailNotify = (data, isError) => {
      if(isError) {
        toast.error(data);
      }
      else {
        toast.success(data);
      }
    }
    return (
        <PageSection id="contact">
            <ContactImg></ContactImg>
            <PageDetails>
                <h2>Contact Us</h2>
                <SocialButtonsContainer><SocialButtons></SocialButtons></SocialButtonsContainer>
                <Formik
                    initialValues={{ email: '', message: '', name: '' }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      const dataStr = JSON.stringify(values);
                      const url = 'https://us-central1-pumpkin-website-721fd.cloudfunctions.net/sendMailOverHTTP';//Release
                      axios({
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: `data=${dataStr}`,
                        url
                      })
                      .then(response => {
                        console.log('response', response);
                        setSubmitting(false);
                        resetForm({ values: '' });
                        emailNotify("Email Sent", false);
                      })
                      .catch(error => {
                        console.log('error', error);
                        setSubmitting(false);
                        emailNotify("There has been a problem sending your request", true);
                      })
                    }}
                >
                    {({ isSubmitting }) => (
                        <StyledForm>
                            <label htmlFor="name">Name</label>
                            <StyledField type="text" name="name" placeholder="Name" id="name"/>
                            <ErrorMessage name="name" component="div" />
                            <label htmlFor="email">Email</label>
                            <StyledField type="email" name="email" placeholder="Email" id="email"/>
                            <ErrorMessage name="email" component="div" />
                            <label htmlFor="message">Type your message here...</label>
                            <StyledField component="textarea" name="message" placeholder="Type your message here..." rows="4" id="message"/>
                            <button type="submit" disabled={isSubmitting}>
                                Submit
                            </button>
                        </StyledForm>
                    )}
                </Formik>
            </PageDetails>
            <ToastContainer position="bottom-center"></ToastContainer>
        </PageSection>
    );
}