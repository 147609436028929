import emailIcon from "img/icons/email.svg";
import homeIcon from "img/icons/home.svg";
import scriptIcon from "img/icons/scriptIcon.svg";
import pumpkinIcon from "img/icons/pumpkinIcon.svg";
import maskIcon from "img/icons/maskIcon.svg";

interface PumpLink {
    label: string;
    title?: string;
    icon: any;
    path: string;
    showLabel: boolean;
};

export const links: PumpLink[] = [
    {label: "Home", icon: homeIcon, path: '/', showLabel: false},
    {label: "Our\xa0Work", icon: pumpkinIcon, path: '/work', showLabel: true},
    {label: "Scripts", icon: scriptIcon, path: '/scripts', showLabel: true},
    {label: "Shows/Events", title: "Shows & Events", icon: maskIcon, path: '/events', showLabel: true},
    {label: "Contact", icon: emailIcon, path: '/#contact', showLabel: false},
];