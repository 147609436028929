import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const width = 250;
const Circle = styled(Link)`
    position: relative;
    height: ${width}px;
    width: ${width}px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(10deg);
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: scale(1.1) rotate(0deg);
    }
    z-index: 10;
    max-width: 100vw;
    svg {
        height: 96%;
        width: 96%;
        filter: drop-shadow( ${width * 0.03}px ${width * 0.03}px ${width * 0.08}px rgba(0, 0, 0, 0.7));
    }
`;
const Description = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    &.top {
        top: 23%;
        font-size: ${width * 0.14}px;    
    }
    top: 62%;
    font-size: ${width * 0.1}px;    
    font-family: "Lobster";
    z-index: 3;
`;
const Title = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: ${width * 0.2}px;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Lobster";
    text-shadow: -${width * 0.02}px ${width * 0.02}px rgba(0,0,0,0.2);
    z-index: 3;
`;

export default function Sticker() {
    return (
        <div>
            <Circle to="/funpack">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <polygon points="50 0 56.88 6.54 65.45 2.45 69.98 10.8 79.39 9.55 81.11 18.89 90.45 20.61 89.2 30.02 97.55 34.55 93.46 43.12 100 50 93.46 56.88 97.55 65.45 89.2 69.98 90.45 79.39 81.11 81.11 79.39 90.45 69.98 89.2 65.45 97.55 56.88 93.46 50 100 43.12 93.46 34.55 97.55 30.02 89.2 20.61 90.45 18.89 81.11 9.55 79.39 10.8 69.98 2.45 65.45 6.54 56.88 0 50 6.54 43.12 2.45 34.55 10.8 30.02 9.55 20.61 18.89 18.89 20.61 9.55 30.02 10.8 34.55 2.45 43.12 6.54 50 0" style={{fill:'#E66F33'}}/>
                </svg>
                <Description className="top">Order Our</Description>
                <Title>Fun Pack</Title>
                <Description>Enjoy Panto at<br />Home!</Description>
            </Circle>
        </div>
    );
}