import React from 'react';
import styled from 'styled-components';
import facebook from '../img/icons/facebook.svg';
import twitter from '../img/icons/twitter.svg';
import instagram from '../img/icons/instagram.svg';
const Link = styled.a`
    img {
        height: 32px;
        width: 32px;       
    }
    & + & {
        margin-left: 24px;
    }
`;
export default function SocialButtons() {
  return (
    <>
      <Link href="https://www.facebook.com/pumpkinpantos" target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook Button"/></Link>
      <Link href="https://twitter.com/pumpkinpantos" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter Button"/></Link>
      <Link href="https://www.instagram.com/pumpkinpantos/" target="_blank" rel="noreferrer"><img src={instagram}  alt="Instagram Button"/></Link>
    </>
  );
}