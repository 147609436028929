import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { PageWrapper, PageSection, PageDetails, PageImg, Sticker } from '../../components';
import ContactSection from './ContactSection';
import disgustJpg from '../../img/disgust.jpg'
import disgustWebp from '../../img/disgust.webp'
import bannerJpg from '../../img/banner.jpg'
import bannerWebp from '../../img/banner.webp'
import pumpkin from '../../img/pumpkin.svg';
import script from '../../img/script.svg';
import masks from '../../img/masks.svg';

const SubSection = styled(Link)`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;
  min-width: 300px;
  min-height: 314px;
  padding-top: 168px;

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 18px;
    letter-spacing: 3px;
    transform: scale(1.1, 1);
    text-align: center;
    width: calc(100% / 1.1);
  }

  p {
    max-width: 300px;
    text-align: center;
  }
  
  img {
    height: 84px;
    position: absolute;
    top: 60px;
  }

  &:nth-child(2) {
    background-color: ${props => props.theme.colours.midPurple};
  }
  &:nth-child(3) {
    background-color: ${props => props.theme.colours.mediumPurple};
  }

  @media (${props => props.theme.maxWidth /2}px) {
    min-height: 250px;
    padding-top: 148px;
    img {
      top: 40px;
    }
  }
`;

const StyledSticker = styled(Sticker)`
  position: absolute;
  left: calc(((100vw - ${props => (props.theme.maxWidth)}px) /2) + ${props => (props.theme.maxWidth)}px);
  top: calc(50% - ${250 /2}px);
  height: 250px;
  width: 250px;
`;

const DisgustImg = styled(PageImg)`
  background-image: url(${() => isWebpSupported() ? disgustWebp : disgustJpg});
  background-size: cover;
  background-position: 50% 20%;
  position: relative;
`;

const CustomPageSection = styled(PageSection)`
  height: 175px;
  background-color: ${props => props.theme.colours.midPurple};
  background-image: url(${() => isWebpSupported() ? bannerWebp : bannerJpg});
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: 50% 50%;
`;
// const MiddleSticker = styled(Sticker)`
//   height: 250px;
//   width: 250px;
//   align-items: center;
//   overflow-x: hidden;
// `;

export default function HomePage() {
  const contact = useRef();
  const location = useLocation();

  const checkContact = () => {
    if(window.location.hash === '#contact') {
      window.scrollTo({left: 0, top: contact.current.offsetTop, behavior: 'smooth'})
    }
  }
  
  useEffect(() => {
    document.title = "Home | Pumpkin Pantos";
    checkContact();
  }, []);

  useEffect(() => {
    checkContact();
  }, [location]);
  
  return (
    <PageWrapper>
      {/* <PageSection className="secondary">SLEEPING BEAUTY 11-20th December 2020 - <strong>Book Tickets Now</strong></PageSection> */}
      <PageSection>
        <DisgustImg></DisgustImg>
        <PageDetails>
          <h1><q>Once Upon A Time...</q></h1>
          <p>Pumpkin Pantos is a professional theatre company based in Hampshire. We are passionate about bringing the joy of family theatre and pantomime to audiences all year round.</p>
          <p>Our productions tour to various Theatres, Art Centres, Community Halls, Schools and Festivals including the Edinburgh Fringe. We also love to perform outside in the sunshine as part of our unique 'Panto in the Park' programme.</p>
          <p>As well as our own productions, we also provide pantomime scripts for theatre companies across the globe with performances of our work reaching as far as Australia.</p>
          <p>If you would like any further information on our work please do get in touch. We would love to hear from you!</p>
        </PageDetails>
      </PageSection>
      {/* <CustomPageSection> */}
        {/* <MiddleSticker> */}
          {/* <Sticker></Sticker> */}
        {/* </MiddleSticker> */}
      {/* </CustomPageSection> */}
      <PageSection>
        <SubSection to="/work">
          <img src={pumpkin} alt="Pumpkin" />
          <h2>Our Work</h2>
          <p>
            As well as producing our spectacular pantomimes all year round, we also run various other projects including interactive theatrical workshops.
          </p>
          <p to="/work">Read More &gt;</p>
        </SubSection>
        <SubSection to="/scripts">
          <img src={script} alt="Script Writing" />
          <h2>Scripts</h2>
          <p>We have a great range of hilarious, fun-filled and action-packed pantomime titles for you to choose from. Licences available for amateur and professional productions.</p>
          <p to="/scripts">Read More &gt;</p>
        </SubSection>
        <SubSection to="/events">
          <img src={masks} alt="Theatre Masks" />
          <h2>Upcoming Events</h2>
          <p>Click here to find out where we are performing next and how you can book tickets for our current events.</p>
          <p>Read More &gt;</p>
        </SubSection>
      </PageSection>
      <div ref={contact}></div>
      <ContactSection></ContactSection>
    </PageWrapper>
  );
}