import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { PageWrapper, PageSection, PageDetails, PageImg, ButtonA, ButtonLink, SeperateQuote, VerticalDivider, Divider } from '../components';
import pinocchioJpg from '../img/pinocchio.jpg'
import pinocchioWebp from '../img/pinocchio.webp'

const PinocchioImg = styled(PageImg)`
  background-image: url(${() => isWebpSupported() ? pinocchioWebp : pinocchioJpg});
  background-size: cover;
  background-position: 50% 20%;
`;

// const SectionFooter = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-bottom: 48px;
//   margin-top: 24px;
// `;

// const PageContent = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
// `;

const ScriptContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: ${props => props.theme.maxWidth + 'px'};
  padding-bottom: 48px;
  justify-content: center;
`;

const ScriptTitle = styled.p`
  flex: 0 50%;
  display: flex;
  &, & > * {
    font-family: ${props => props.typeface ? '"' + props.typeface + '",' : ''} cursive;
  }
  text-align: center;
  font-size: ${props => props.size || '48'}px;
  margin: 48px 0 0 0;
  justify-content: center;
  flex-direction: column;
  min-width: ${props => props.theme.maxWidth / 3 + 'px'};

  strong {
    font-size: 60px;
    font-weight: inherit;
    line-height: 0.75;
  }
  aside {
    font-size: 0.75em;
  }

  @media (max-width: 450px) {
    min-width: 100vw;
    font-size: ${props => props.size * 0.75 || '36'}px;
    strong { 
      font-size: 45px;
    }
  }
`;

const Ul = styled.ul`
  margin-top: 0;
`;


export default function ScriptPage() {
  useEffect(() => {
    document.title = "Scripts | Pumpkin Pantos";
  }, []);

  return (
    <PageWrapper>
      <PageSection>
        <PinocchioImg alt="Pinocchio and Cricket"></PinocchioImg>
        <PageDetails>
          <h1>Scripts</h1>
          <p>We have a vast range of hilarious, fun-filled and action-packed pantomime titles for you to choose from.</p>
          <p>All of our scripts (written by professional pantomime practitioner Josh Dixon) are bursting with colourful characters, strong storylines and bucket loads of comedy to keep audiences of all ages enthralled from beginning to end.</p>
          <p>We update our scripts annually to ensure they are kept fresh and modern whilst still retaining the well-loved and traditional elements of pantomime!</p>
          <p>If you can't find what you're looking for or fancy something completely different you might like to take advantage of our bespoke writing service. We can write the perfect panto to suit your requirements! <Link to="/#contact">Contact us</Link> for more details.</p>
          <Divider></Divider>
          <SeperateQuote>
            <q>Josh Dixon’s script is an absolute delight. It contains virtually every wonderful attribute of classic pantomime.</q>
            <figcaption>Stage Whispers</figcaption>
          </SeperateQuote>
          <SeperateQuote>
            <q>The script, penned by Josh Dixon has you giggling from beginning to end!</q>
            <figcaption>Tindle Newspapers</figcaption>
          </SeperateQuote>
        </PageDetails>
      </PageSection>
      <PageSection className="mid">
        <ScriptContainer>
          <ScriptTitle typeface="Pacifico">Sleeping Beauty</ScriptTitle>
          <ScriptTitle typeface="Princess Sofia">Snow White<br />&amp; the Seven Dwarfs</ScriptTitle>
          <ScriptTitle typeface="Metal Mania" size="60">The Wizard of Oz</ScriptTitle>
          <ScriptTitle typeface="Spicy Rice">Red Riding Hood</ScriptTitle>
          <ScriptTitle typeface="Shojumaru" size="60">Aladdin</ScriptTitle>
          <ScriptTitle typeface="Original Surfer"><strong>Jack</strong><aside>and the Beanstalk</aside></ScriptTitle>
          <ScriptTitle typeface="Lakki Reddy" size="60">Robin Hood</ScriptTitle>
          <ScriptTitle typeface="Henny Penny">The Frog Prince</ScriptTitle>
          <ScriptTitle typeface="Berkshire Swash"><strong>Robinson Crusoe</strong><aside>&amp; the Pirates</aside></ScriptTitle>
          <ScriptTitle typeface="Ewert"><strong>Pinocchio</strong></ScriptTitle>
          {/* <ScriptTitle typeface="Sunshiney"><strong>Alice in Wonderland</strong></ScriptTitle> */}
          <ScriptTitle typeface="Sunshiney"><strong>Beauty and the Beast</strong></ScriptTitle>
          <ScriptTitle typeface="Ruge Boogie" size="80">Rapunzel</ScriptTitle>
        </ScriptContainer>
      </PageSection>
      <PageSection className="medium">
        <PageDetails>
          <h2>Free Reading Copies</h2>
          <p>​A free reading copy of any script is available upon request.</p>
          {/* <ButtonA href="https://docs.google.com/forms/d/e/1FAIpQLSfN5LDOyEEBvyxnlyVkBa6KgjFU_5snjrTKkJiihuZAGBJojQ/viewform" target="_blank" rel="noopener noreferrer">Click here to obtain a reading copy</ButtonA> */}
          <ButtonLink to="/#contact">Message us to obtain a reading copy</ButtonLink>
          <p>Please note:</p>
          <Ul>
            <li>A reading copy of any pantomime is for review only and does not grant the rights for performance.</li>
            <li>A <a href="https://docs.google.com/forms/d/e/1FAIpQLSeI15oJ5mnXObI7RJaCIuZKi_v7ERV6vjMdkKlL0zyhDOe1ig/viewform" target="_blank" rel="noopener noreferrer">licence</a> must be obtained and the appropriate royalties paid if you wish to use the script for performance. </li>
            <li>No script should be reproduced either in whole or in part without permission to do so.</li>
          </Ul>
        </PageDetails>
        <VerticalDivider></VerticalDivider>
        <PageDetails>
          <h2>Licence &amp; Fees</h2>
          <p>Click below to apply for a performance licence.</p>
          {/* <ButtonA href="https://docs.google.com/forms/d/e/1FAIpQLSeI15oJ5mnXObI7RJaCIuZKi_v7ERV6vjMdkKlL0zyhDOe1ig/viewform" target="_blank" rel="noopener noreferrer">Performance licence form</ButtonA> */}
          <p>
            Up to 200 seats: £40 per performance<br />
            201 - 400 seats: £50 per performance<br />
            401- 600 seats : £60 per performance<br />
            Seating over 600: £70 per performance<br />
            Video/DVD Recording Licence: £25<br />
          </p>
          <aside>The following fees are for amateur groups only. Licences for professional companies must be arranged separately.</aside>
        </PageDetails>
      </PageSection>
      <PageSection className="mid">
        <PageDetails>
          <SeperateQuote>
            <q>Audiences were spellbound and said it was one of the best panto's we had done. In fact, we loved the script so much, we are now doing another Pumpkin Pantos’ title!</q>
            <figcaption>Damon Hill, Tea Tree Players, Adelaide, Australia</figcaption>
          </SeperateQuote>
        </PageDetails>
        <PageDetails>
          <SeperateQuote>
            <q>Josh Dixon's quirky and quick-fire script ensured a plethora of laughs from the word go!</q>
            <figcaption>Edinburgh Fringe Review</figcaption>
          </SeperateQuote>
        </PageDetails>
      </PageSection>
    </PageWrapper>
  );
}