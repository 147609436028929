const theme = {
    colours: {
        primary: '#4A124D', //Purple
        secondary: '#E66F33', //Orange
        secondaryHover: '#D96226', //Orange
        secondaryActive: '#CD561A', //Orange
        darkPurple: '#2F1031',
        midPurple: '#5F1F63',
        mediumPurple: '#8E2F94',
        lightPurple: '#C75ECE',
        white: '#FFFFFF',
        black: '#000000',
        dark: '#181818'
    },
    maxWidth: 1280
};

export default theme;