import styled from 'styled-components';
import { Link } from "react-router-dom";
// import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";

import Modal from './Modal.js';
import Sticker from './Sticker.js';

export { Modal };
export { Sticker };


export const PageWrapper = styled.div`
  background: ${props => props.theme.colours.secondary};
`;

// export const PageSection = styled(ScrollAnimation).attrs(props => ({
//   animateIn: "fadeIn",
//   animateOnce: true
// }))`
export const PageSection = styled.div<{direction?: string, alignItems?: string, mobileReverse?: boolean}>`
  position: relative;
  border-top: 12px solid ${props => props.theme.colours.secondary};
  background: ${props => props.theme.colours.darkPurple};
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  flex-wrap: wrap;
  justify-content: center;
  ${props => props.alignItems ?? `align-items: ${props.alignItems};`}

  p, li {
    line-height: 1.5;
  }

  p, ul, h1, h2, h3 {
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    width: 100%;
    font-weight: 400;
    text-align: center;
  }
  h1 > q {
    font-style: italic;
  }
  h2 {
    margin: 0;
    padding: 0 8px;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    box-sizing: border-box;
  }

  &.mid {
    background: ${props => props.theme.colours.midPurple};
  }

  &.medium {
    background: ${props => props.theme.colours.mediumPurple};
  }

  &.light {
    background: ${props => props.theme.colours.lightPurple};
  }

  &.secondary {
    background: ${props => props.theme.colours.secondary};
    color: white;
  }

  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    flex-direction: ${props => props.mobileReverse ? 'column-reverse' : 'column'};
  }
  &.continue {
    border-top: 0;
  }
`;

export const PageDetails = styled.div<{wide?: boolean, align?: string}>`
  flex: 1;
  max-width: ${props => props.theme.maxWidth / (props.wide ? 1 : 2) + 'px'};
  h1 {
    margin-top: 0;
    text-transform: uppercase;
    transform: scale(1.05, 1);
    width: 100%;
    font-weight: 400;
    text-align: center;
  }
  h1 > q {
    font-style: italic;
  }
  h2, h4 {
    margin: 8px 0;
    margin-top: 0;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
    font-weight: 300;
    font-size: 30px;
  }
  h3 {
    margin: 0 0 16px 0;
    font-weight: normal;
    font-size: inherit;
    text-align: center;
  }
  h4 {
    font-size: 24px;
  }
  p {
    margin: 8px 0;
  }
  p + a {
    margin: 16px auto 16px 0;
  }
  p + a:last-child {
    margin: 30px 0 0 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  aside {
    margin-top: 1em;
    font-size: 0.75em;
    width: 100%;
  }
  margin: 48px 12px;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  padding: 0 24px;

  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    margin: 24px 12px;
  }
`;

export const PageImg = styled.div`
  flex: 1;
  max-width: ${props => props.theme.maxWidth /2 + 'px'};
  background-repeat: no-repeat;
  background-position: right;
  margin: 24px 12px 24px 0;

  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    height: 320px;
    flex: auto;
    margin: 0;
  }
`;

export const Button = styled.button`
  appearance: button;
  text-decoration: none;
  color:  ${props => props.theme.colours.white};
  background:  ${props => props.theme.colours.secondary};
  border: 0;
  outline: 0;
  border-radius: 20px;
  padding: 12px 30px;
  &:active {
    background: ${props => props.theme.colours.secondaryActive};
  }
  &:hover {
    background: ${props => props.theme.colours.secondaryHover};
  }
  cursor: pointer;
`;

export const ButtonLink = styled(Link)`
  appearance: button;
  text-decoration: none;
  color:  ${props => props.theme.colours.white};
  background:  ${props => props.theme.colours.secondary};
  border-radius: 20px;
  padding: 12px 30px;
  &:active {
    background: ${props => props.theme.colours.secondaryActive};
  }
  &:hover {
    background: ${props => props.theme.colours.secondaryHover};
  }
  cursor: pointer;
`;

export const ButtonA = styled.a`
  appearance: button;
  text-decoration: none;
  color:  ${props => props.theme.colours.white};
  background:  ${props => props.theme.colours.secondary};
  border-radius: 20px;
  padding: 12px 30px;
  &:active {
    background: ${props => props.theme.colours.secondaryActive};
  }
  &:hover {
    background: ${props => props.theme.colours.secondaryHover};
  }
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }

  & + & {
    margin-left: 24px;
  }
  @media (max-width: ${props => props.theme.maxWidth /1.5}px) {
    & + & {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

export const Divider = styled.div`
  &:before {
    content: '\u2014';
    display: block;
    font-size:120px;
    text-align: center;
    line-height: 0px;
    margin: 24px 0 28px 0;
    transform: scale(2, 0.5);
  }
`;

export const VerticalDivider = styled.div`
  border: 1px white solid;
  margin: 48px 0;
  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    margin: 0;
  }
`;

export const Quote = styled.figure`
  text-align: center;
  q {
    font-style: italic;
    
  }
  figcaption {
    font-size: 11px;
    text-align: center;
    margin-top: 18px;
  }
  figcaption:before {
    content: '- ';
  }
`;

export const SeperateQuote = styled.div`
  position:relative;
  padding-left: 25px;
  margin: 10px 0 10px 75px;
  width: calc(100% - 75px);  

  &:before {
    content: '\u201D';
    -webkit-text-stroke: 2px white;
    font-family: Arial, sans-serif;
    font-size: 150px;
    color: rgba(0,0,0,0);
    position: absolute;
    left: -50px;
    line-height: 125px;
  }
  q {
    font-style: italic;
    line-height: 1.5;
  }
  figcaption {
    font-size: 12px;
    margin-top: 12px;
  }
  figcaption:before {
    content: '- ';
  }
`;

// export const IconText = styled.p<{icon: string}>`
//   background-image: url(${props => props.icon});
//   background-position: left 4px top 50%;
//   background-repeat: no-repeat;
//   min-height: 48px;
//   background-size: 32px;
//   padding-left: 48px;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   transition: background 0.3s;
//   margin-left: -4px !important;
//   &:hover {
//     background-size: 40px;
//     background-position: left 0px top 50%;
//   }
// `;

export const Flex = styled.div<{direction?: string, justifyContent?: string, alignItems?: string}>`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "center"};
`;