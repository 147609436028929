import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const location = useLocation();
    useEffect((previousLocation) => {
      if (location !== previousLocation) {
        window.scrollTo(0, 0);
      }
    }, [location]);
  return (
    <></>
  );
}
