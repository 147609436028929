import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { PageWrapper, PageSection, PageDetails, PageImg, ButtonLink, SeperateQuote, Divider } from '../components';
// import popupJpg from '../img/popup.jpg'
// import popupWebp from '../img/popup.webp'
// import pieJpg from '../img/pie.jpg'
// import pieWebp from '../img/pie.webp'
import beansJpg from '../img/beans.jpg'
import beansWebp from '../img/beans.webp'
import rrhJpg from '../img/rrh.jpg'
import rrhWebp from '../img/rrh.webp'
// import sleepingDamePng from '../img/sleepingDame.png'
// import sleepingDameWebp from '../img/sleepingDame.webp'

// const PieImg = styled(PageImg)`
//   background-image: url(${() => isWebpSupported() ? pieWebp : pieJpg});
//   background-size: cover;
//   background-position: 50% 20%;
// `;

const RrhImg = styled(PageImg)`
  background-image: url(${() => isWebpSupported() ? rrhWebp : rrhJpg});
  background-size: cover;
  background-position: 50% 20%;
`;

const BeansImg = styled(PageImg)`
  background-image: url(${() => isWebpSupported() ? beansWebp : beansJpg});
  background-size: cover;
  background-position: 50% 20%;
`;

// const SleepingDameImg = styled(PageImg)`
//   background-image: url(${() => isWebpSupported() ? sleepingDameWebp : sleepingDamePng});
//   background-size: cover;
//   background-position: 50% 55%;
// `;

// const PopupImg = styled(PageImg)`
// background-image: url(${() => isWebpSupported() ? popupWebp : popupJpg});
//   background-size: cover;
//   background-position: 22% 35%;
// `;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  iframe {
    flex: 0 0 calc(50% - 12px);
    aspect-ratio: 16 / 9;
    max-width: calc(100vw - 72px);
    margin-bottom: 12px;
    height: auto;
  }
`

export default function WorkPage() {
  useEffect(() => {
    document.title = "Our Work | Pumpkin Pantos";
  }, []);
  
  return (
    <PageWrapper>
      <PageSection>
        <BeansImg></BeansImg>
        <PageDetails>
          <h1>Productions</h1>
          <p>Pumpkin Pantos produce a number of spectacular pantomimes throughout the year.</p>
          <p>We strive to ensure our shows are high energy, fast paced and topical with excellent production values, side-splitting comedy and catchy musical numbers.</p>
          <Divider></Divider>
          <SeperateQuote>
            <q>Once again Pumpkin Pantos have pulled it out of the bag with a show full of fun, gags, great costumes and a brilliant cast!</q>
            <figcaption>Audience review</figcaption>
          </SeperateQuote>
          <ButtonLink to="/events">Upcoming Events</ButtonLink>
        </PageDetails>
      </PageSection>
      {/* <PageSection className="mid" mobileReverse>
        <PageDetails>
          <h1>Pop-up Panto</h1>
          <h3><i>The pantomime that comes to you!</i></h3>
          <p>Our sixty minute pop-up pantomimes feature a cast of professional performers, laugh out loud comedy, toe-tapping songs, colourful costumes and a freestanding set suitable for any sized performance space.</p>
          <p>Our Pop-Up Pantos are perfect for schools, birthday parties and private events. We can even perform outside at fairs and festivals.</p>
          <ButtonLink to="/#contact">Make an enquiry</ButtonLink>
        </PageDetails>
        <RrhImg></RrhImg>
      </PageSection> */}
      <PageSection className="mid" mobileReverse>
        <PageDetails>
          <h1>Workshops</h1>
          <p>'The Magic of Pantomime' is an interactive workshop that explores the history, characters and stories of panto through drama games, group exercises and music. Participants have the chance to bring stories to life by re-creating famous panto scenes and portraying their favourite characters.</p>
          <p>Our drama workshops aim to encourage creativity and develop and strengthen children's social skills all whilst having fun! Suitable for ages 5 - 13.</p>
          <ButtonLink to="/#contact">Make an enquiry</ButtonLink>
        </PageDetails>
        <RrhImg></RrhImg>
      </PageSection>
      {/* <PageSection className="mid" mobileReverse> className="light"
        <PageDetails>
          <h1>Parties &amp; Events</h1>
          <p>Let the Pumpkin Pantos team bring some magic to your special occasion.</p>
          <p>Below are some examples of what we can offer, but we are more than happy to create a bespoke package to suit your needs.</p>
          <ul>
            <li>Character meet and greet</li>
            <li>Pop-Up Panto performances (indoors or outdoors)</li>
            <li>Themed parties based on your child's favourite story. </li>
            <li>Mad Hatter's Interactive Tea Party</li>
            <li>Pirate Treasure Hunt</li>
            <li>Princess Ball</li>
          </ul>
          <ButtonLink to="/#contact">Make an enquiry</ButtonLink>
        </PageDetails>
        <PopupImg></PopupImg>
      </PageSection> */}
      <PageSection>
        <PageDetails wide>
          <h4>Trailers from previous Summer Pantos...</h4>
          <VideoWrapper>
            <iframe src="https://www.youtube.com/embed/nFzsmyhBaas" height="256" width="455" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" title="YouTube - Pinocchio: The Panto"></iframe>
            <iframe src="https://www.youtube.com/embed/pmDWyY3zkdU" height="256" width="455" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" title="YouTube - Red Riding Hood: The Panto"></iframe>
            <iframe src="https://www.youtube.com/embed/SO5C2aedvLY" height="256" width="455" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" title="YouTube - Alice in Wonderland"></iframe>
            {/* <iframe src="https://www.youtube.com/embed/aCnU0j6ZfZI" height="256" width="455" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" title="YouTube - Treasure Island: The Panto"></iframe> */}
          </VideoWrapper>
        </PageDetails>
      </PageSection>
    </PageWrapper>
  );
}