import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import theme from './theme';
import './index.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ToastContainer position="bottom-center"></ToastContainer>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);